<template>
    <div class="bg-white">
        <div class="series-banner">
            <div class="container">
                <div class="row no-gutters text-center text-sm-left align-items-center align-items-md-start align-self-sm-center">
                    <div class="col-12 col-sm-6 col-lg-7 col-xl-6"
                        :class="{ 'align-self-md-end' : serie.squareImg }">
                        <img v-bind:src="require('@/assets/' + serie.imgSource)"
                             class="img-fluid product-img responsive p-3"
                             :class="{'square-img' : serie.squareImg, 'rectangle-img' : !serie.squareImg}"
                             :alt="serie.pathName">
                    </div>
                    <div class="col-12 col-sm-6 col-lg-5 col-xl-6">
                        <div class="details-wrapper py-3 text-sm-left">
                            <h1 class="mb-sm-3">Husa telefon Honeycomb Armor</h1>
                            <div class="series-color mb-sm-2 mb-md-3">
                                <p class="product-properties">Culori disponibile:</p>
                                <div v-for="color in serie.colors"
                                     :key="color"
                                     class="color"
                                     :class="color">
                                </div>
                            </div>
                            <div class="recommended-price">
                                <p class="product-properties">Pret recomandat:</p>
                                <p class="price font-weight-bold">{{ serie.price }} RON</p>
                            </div>
                            <div class="call-to-action mt-2 mt-md-4 d-xl-flex justify-content-between justify-content-xl-start">
                                <a href="https://shop.koff.ro/register" target="_blank"
                                   class="btn btn-black d-block mx-auto mb-4 mx-sm-0 mb-lg-3 mr-xl-2 mb-xl-0">{{ $t('navbar.distributor').toUpperCase() }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="product-details">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="section-title text-center">Detalii produs</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="description-blocks bg-white d-flex flex-wrap justify-content-between">
                            <div class="block order-0 order-md-0 col-md-6 p-0 img-block">
                                <img src="@/assets/img/blocks/1.jpg" class="img-responsive" alt="#">
                            </div>
                            <div class="block order-1 order-md-1 col-md-6 p-5 bg-white text-block text-center align-self-center">
                                <h4 class="feature-title">Aderenta sporita</h4>
                                <p class="feature-desc">
                                    Design-ul atent gandintit ofera protectie
                                    telefonului tau si totodata o prindere mai buna
                                    a acestuia in timpul folosirii.</p>
                            </div>
                            <div class="block order-3 order-md-2 col-md-6 p-5 bg-white text-block text-center align-self-center">
                                <h4 class="feature-title">Multiple culori disponibile</h4>
                                <p class="feature-desc">
                                    Alge o husa pentru telefonul tau dintr-o
                                    gama variata de culori pentru seria
                                    HoneyComb Armor.</p>
                            </div>
                            <div class="block order-2 order-md-3 col-md-6 p-0 img-block">
                                <img src="@/assets/img/blocks/2.jpg" class="img-responsive" alt="#">
                            </div>
                            <div class="block order-4 order-md-4 col-md-6 p-0 img-block">
                                <img src="@/assets/img/blocks/3.jpg" class="img-responsive" alt="#">
                            </div>
                            <div class="block order-5 order-md-5 col-md-6 p-5 bg-white text-block text-center align-self-center">
                                <h4 class="feature-title">Structura imbunatatita</h4>
                                <p class="feature-desc">
                                    Modelul hexagonal intareste suprafețele interioare
                                    ale carcasei îmbunătățind absorbția
                                    șocurilor cu până la 10%.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="becomepartner">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <img src="../assets/img/banner-distribuitor.jpg" class="img-responsive" alt="devino distribuitor mobster">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Serie',

        props: ['products'],

        data() {
            return {
                // serie: this.serieData()
            }
        },

        computed: {
            serie() {
                const allBrandSeries = this.products.filter(product => {
                    return product.brand === this.$route.params.brand;
                });

                const serieData = allBrandSeries[0].models.filter(serie => {
                    return serie.pathName === this.$route.params.serie
                });

                return serieData[0];
            }
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/series.scss';

    img.product-img {
        max-height: 340px;
        @media (min-width: 768px) {
            max-height: unset;
            /*position: absolute;*/
            /*padding-top: 100px!important;*/
        }
    }

    .series-banner {
        height: unset;
        margin-top: 0;
        @media (min-width: 768px) {
            height: 520px;
        }
    }

    .series-banner .details-wrapper h1 {
        font-size: 1.3rem;
        @media (min-width: 576px) {
            font-size: 1.5rem;
        }
        @media (min-width: 768px) {
            font-size: 1.8rem;
            margin-bottom: 35px!important;
        }
    }

    .details-wrapper {
        @media (min-width: 768px) {
            padding-top: 90px!important;
            padding-bottom: 0!important;
        }
    }

    #product-details {
        @media (min-width: 768px) {
            padding-bottom: 0;
        }
    }

    #becomepartner {
        @media (min-width: 768px) {
            padding-top: 0!important;
        }
    }

    .section-title {
        @media (min-width: 768px) {
            margin-bottom: 80px!important;
        }
    }

    img.square-img {
        @media (min-width: 768px) {
            max-height: 520px;
        }
    }

    img.rectangle-img {
        @media (min-width: 768px) {
            position: absolute;
            top: 86px;
        }
    }

</style>